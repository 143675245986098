<template>
  <div class="pro_box">
    <div class="title flex_center_between_box">
      <div class="title_txt flex_center_start_box"><img src="../../assets/img/detail/title_ic10.png" />Country Profile</div>
      <div class="btn_export flex_center" @click="viewHistory"><img src="../../assets/img/detail/btn_ic4.png" />View History</div>
    </div>
    <div class="right_cont_box">
      <div class="group profile">
        <div class="item">
          <label class="lab">Country Name:</label>
          <div class="txt" v-show="!showTranslate">{{ objProfile.CountryName ? objProfile.CountryName : '-' }}</div>
          <div class="txt" v-show="showTranslate">{{ objProfile.CountryNameCn ? objProfile.CountryNameCn : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">ISO Code:</label>
          <div class="txt">{{ objProfile.ISOCode ? objProfile.ISOCode : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Region:</label>
          <div class="txt" v-show="!showTranslate">{{ objProfile.Region ? objProfile.Region : '-' }}</div>
          <div class="txt" v-show="showTranslate">{{ objProfile.regionCn ? objProfile.regionCn : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Capital:</label>
          <div class="txt" v-show="!showTranslate">{{ objProfile.Capital ? objProfile.Capital : '-' }}</div>
          <div class="txt" v-show="showTranslate">{{ objProfile.capitalCn ? objProfile.capitalCn : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Land Size:</label>
          <div class="txt">{{ objProfile.LandSize ? objProfile.LandSize : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Economic Organization:</label>
        
          <div class="txt line_1"  v-if="objProfile.EconomicOrganization"  style="width: 702px">
          <ul class="EconomicOrganization" >
            <li class="EconomicOrganizationtitle" v-for="(item,index) in objProfile.EconomicOrganization" :key="index" @click="setid(item.web)">{{ item.organizationEn }}</li>
          </ul>
          </div>
          <div class="txt line_1" v-else style="width: 702px">{{ '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Population:</label>
          <div class="txt">{{ objProfile.Population ? objProfile.Population : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Population Growth:</label>
          <div class="txt">{{ objProfile.PopulationGrowth ? objProfile.PopulationGrowth : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Language:</label>
          <div class="txt" v-show="!showTranslate">{{ objProfile.Language ? objProfile.Language : '-' }}</div>
          <div class="txt" v-show="showTranslate">{{ objProfile.languageCn ? objProfile.languageCn : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Currency:</label>
          <div class="txt" v-show="!showTranslate">{{ objProfile.Currency ? objProfile.Currency : '-' }}</div>
          <div class="txt" v-show="showTranslate">{{ objProfile.currencyCn ? objProfile.currencyCn : '-' }}</div>
        </div>
        <div class="item">
          <label class="lab">Foreign Exchang: </label>
          <div class="txt">{{ objProfile.ForeignExchang ? objProfile.ForeignExchang : '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { searchCountryInfo } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'

export default {
  components: {},
  data() {
    return {
      objProfile: { CountryName: '', CountryNameCn: '', ISOCode: '', Region: '', regionCn: '', Capital: '', capitalCn: '', LandSize: '', EconomicOrganization: [], Population: '', PopulationGrowth: '', Language: '', languageCn: '', Currency: '', ForeignExchang: '' },
      companyCountry:'',
    }
  },
  computed: {
    //index传值 是否翻译
    showTranslate: {
      get() {
        return this.$parent.indexIsTranslate
      },
      set() {},
    },
  },
  watch: {},

  created() {
    this.getData()
  },
  methods: {
    setid(row){
      if(row.includes('http')){
    console.log(`true`, true);
    let routeData=row
    window.open(routeData, '_blank');
}else{
    let routeData='http://'+row
window.open(routeData, '_blank');
}
    },
    getData() {
      this.companyCountry = this.$route.query.companyCountry

      const params = {
        types: ['CP'],
        countryCodes: [this.companyCountry],
        // years: [''],
      }

      searchCountryInfo(params).then((result) => {
        let data = JSON.parse(crypto.decrypt(result))
        if (data && data.code && data.data) {
          console.log('result- profile', data.data)
          let obj = data.data.countryProfileList ? data.data.countryProfileList[0] : ''
          this.objProfile = {
            CountryName: obj.countryName,
            CountryNameCn: obj.countryNameCn,
            ISOCode: obj.isocode,
            Region: obj.region,
            regionCn: obj.regionCn,
            Capital: obj.capital,
            capitalCn: obj.capitalCn,
            LandSize: obj.landsize,
            EconomicOrganization: obj.organizationsItem,
            Population: obj.population,
            PopulationGrowth: obj.populationGrowth,
            Language: obj.language,
            languageCn: obj.languageCn,
            Currency: obj.currency,
            currencyCn: obj.currencyCn,
            ForeignExchang: obj.foreignExchang,
          }
        }
      })
    },
    //跳转
    viewHistory() {
      this.$router.push({ path: '/country/viewhistory', query: { companyCountry: this.companyCountry} })
    },
  },
}
</script>
<style scoped>
.title {
  margin-bottom: 20px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.pro_box {
  margin-top: 22px;
}
.group.profile {
  padding: 0 10px;
}
.profile .item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7eaed;
}
.profile .item:first-child {
  padding-top: 10px;
}
.profile .item:last-child {
  padding-bottom: 10px;
  border-width: 0;
}
.profile .item .lab {
  width: 178px;
  font-size: 16px;
  color: #8497ab;
}
.profile .item .txt {
  margin-left: 40px;
  color: #022955;
  font-size: 16px;
}
.EconomicOrganization{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}
.EconomicOrganizationtitle{
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
    white-space: nowrap;
  height: 30px;
  line-height: 30px;
  /* text-decoration: underline; */
    color: rgb(18, 144, 201);
    cursor: pointer;
  list-style: disc;
    list-style-position: inside;
}
@media (max-width: 821px) {

.EconomicOrganizationtitle{
  width: 100% !important;
}
.profile .item .txt{
  font-size: 14px;
}
.profile .item .lab{
  width: 100% !important;
}
}
/* li:before {
  content: "•";
  color: black;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
} */

</style>
